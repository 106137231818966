<template>
  <b-container class="my-5">
    <b-card no-body class="overflow-hidden">
      <b-row no-gutters>
        <b-col md="5">
          <b-img style="height: 100%;width: 100%" src="../assets/aboutMe.png" class="rounded-0"></b-img>
        </b-col>
        <b-col md="7" class="py-3">
          <b-card-body title="在线留言">
            <b-card-text class="small">解决您的需求，就是我们的使命</b-card-text>
            <hr>
            <b-card-text>
              <div class="my-4">性别:
                <b-form-radio-group v-model="form.sex" :options="['男', '女']" required/>
              </div>
              <div class="my-4">
                您提交的时间:
                <b-form-datepicker id="datetime" v-model="form.datetime" class="mb-2" required/>
              </div>
              <div class="my-4">
                您的地区:
                <b-row>
                  <b-col>
                    <b-form-select v-model="form.province" type="text" :options="locations.province" required/>
                  </b-col>
                  <b-col>
                    <b-form-select v-model="form.city" type="text" :options="locations.city" required/>
                  </b-col>
                  <b-col>
                    <b-form-select v-model="form.area" type="text" :options="locations.area" required/>
                  </b-col>
                </b-row>
              </div>
              <div class="my-4">
                您的年龄段:
                <b-form-select v-model="form.age" :options="aged" required></b-form-select>
              </div>
              <div class="my-4">
                您的留言:
                <b-form-textarea
                  id="textarea"
                  v-model="form.text"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                  required
                ></b-form-textarea>
              </div>
            </b-card-text>
          </b-card-body>
          <b-btn @click="submitInfor" variant="outline-primary" class="float-right mr-3 px-5">提交</b-btn>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Feed',
  data () {
    return {
      form: {
        sex: '男',
        datetime: new Date(),
        age: null,
        text: null,
        province: '北京市',
        city: null,
        area: null
      },
      aged: [
        {
          value: null,
          text: '请选择一个年龄段'
        },
        {
          value: '0-15',
          text: '0岁-15岁'
        },
        {
          value: '16-25',
          text: '16岁-25岁'
        },
        {
          value: '26-35',
          text: '26岁-35岁'
        },
        {
          value: '36-55',
          text: '36岁-55岁'
        },
        {
          value: '56-75',
          text: '56岁-75岁'
        },
        {
          value: '76-200',
          text: '76岁以上'
        }
      ],
      locations: {
        province: [],
        city: [],
        area: []
      },
      cacheCitys: null
    }
  },
  created () {
    axios.get('./api/city.json').then(res => {
      this.cacheCitys = res.data
      this.openCity(res.data)
      this.open2City(this.form.province)
      this.open3City(this.form.province, this.form.city)
    })
  },
  methods: {
    openCity (data) {
      data.forEach(item => {
        this.locations.province.push(item.provinceName)
      })
    },
    open2City (province) {
      this.cacheCitys.forEach(item => {
        if (item.provinceName === province) {
          this.locations.city = []
          item.cities.forEach(i => {
            this.locations.city.push(i.cityName)
          })
          this.form.city = this.locations.city[0]
        }
      })
    },
    open3City (province, city) {
      this.cacheCitys.forEach(item => {
        if (item.provinceName === province) {
          item.cities.forEach(i => {
            if (i.cityName === city) {
              this.locations.area = []
              i.counties.forEach(area => {
                if (area.countyName !== '市辖区') {
                  this.locations.area.push(area.countyName)
                }
              })
            }
            this.form.area = this.locations.area[0]
          })
        }
      })
    },
    submitInfor () {
      axios.post(this.$store.state.url + '/onlineMessage', this.form).then(res => {
        if (res.data === 'success') {
          alert('提交成功')
          location.reload()
        } else {
          alert('网络问题，提交失败')
        }
      })
    }
  },
  watch: {
    'form.province' (province) {
      this.open2City(province)
      this.open3City(province, this.form.city)
    },
    'form.city' (city) {
      this.open3City(this.form.province, city)
    }
  }
}
</script>

<style scoped>

</style>
