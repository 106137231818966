<template>
  <b-container class="my-5">
    <b-row>
      <b-col md="3" class="d-none d-md-block">
        <b-list-group>
          <b-list-group-item class="font-weight-bold">推荐文章</b-list-group-item>
          <b-list-group-item v-for="(item,key) in newsTitle" :key="key" :href="item.link" class="small" @click="refreshArticle(item.title)">
            {{item.title.slice(0,15)}}..
          </b-list-group-item>
          <b-list-group-item href="#foobar">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              size="sm"
              align="fill"
              class="m-0 p-0"
            ></b-pagination>
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col md="9">
        <b-card class="mb-2">
          <b-card-text>
            <h3 v-text="article.title"></h3>
            <div class="my-3">作者： {{ article.author }}</div>
            <div v-html="article.content"></div>
          </b-card-text>
          <b-button href="#" variant="primary">查看详情</b-button>
        </b-card>
      </b-col>
    </b-row>
    <div class="text-right">
      <a href="javascript:" @click="changeArticle(0)">上一篇</a>
      <a href="javascript:" @click="changeArticle(1)" class="ml-3">下一篇</a>
    </div>
  </b-container>
</template>

<script>
import axios from 'axios'
export default {
  name: 'News',
  data () {
    return {
      newsTitle: '',
      currentPage: 1,
      perPage: 10,
      rows: 0,
      currentTitle: '',
      article: {
        title: '',
        author: '',
        content: ''
      }
    }
  },
  created () {
    axios.get(this.$store.state.url + '/getNewsList').then(res => {
      this.newsTitle = res.data.news
      this.rows = res.data.total
      this.refreshArticle(this.newsTitle[0].title)
    })
  },
  watch: {
    currentPage () {
      axios.get(this.$store.state.url + '/refreshPag?page=' + this.currentPage).then(res => {
        this.newsTitle = res.data.news
      })
    }
  },
  methods: {
    refreshArticle (title) {
      this.currentTitle = title
      axios.get(this.$store.state.url + '/getArticle?title=' + title).then(res => {
        this.article.title = res.data.title
        this.article.author = res.data.author
        this.article.content = res.data.content
      })
    },
    changeArticle (method) {
      if (method === 0) {
      // 上一篇文章
        for (let i = 0; i < this.newsTitle.length; i++) {
          if (this.newsTitle[i].title === this.currentTitle) {
            if (i === 0) {
              alert('已经是第一页了')
              break
            }
            this.refreshArticle(this.newsTitle[i - 1].title)
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            break
          }
        }
      } else {
      // 下一篇文章
        for (let i = 0; i < this.newsTitle.length; i++) {
          if (this.newsTitle[i].title === this.currentTitle) {
            if (i === this.newsTitle.length - 1) {
              alert('已经是最后一页了')
              break
            }
            this.refreshArticle(this.newsTitle[i + 1].title)
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            break
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
