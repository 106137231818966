<template>
  <b-container fluid class="my-4">
    <b-row>
      <b-col md="2" class="d-none d-md-block">
        <b-list-group>
          <b-list-group-item button class="font-weight-bolder">城市数据</b-list-group-item>
          <b-list-group-item button class="small" v-for="(item,key) in queryData.slice(0,10)" :key="key">
            {{ key + 1 }} 、 {{ item.name }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col md="8">
        <div id="main" style="width: 100%;height: 700px"></div>
      </b-col>
      <b-col md="2" class="d-none d-md-block">
        <b-list-group>
          <b-list-group-item button class="font-weight-bolder">灾情排行榜</b-list-group-item>
          <b-list-group-item button class="small" v-for="(item,key) in rank" :key="key">
            {{ key+1 }}、{{ item.name }} : {{ item.value }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as echarts from 'echarts'
import axios from 'axios'

export default {
  name: 'Map',
  data () {
    return {
      chinaData: null,
      queryData: [],
      minValue: null,
      maxValue: null,
      currentPage: 1,
      perPage: 3,
      rows: 0,
      rank: []
    }
  },
  created () {
    axios.get(this.$store.state.url + '/getMapData').then(async res => {
      this.minValue = res.data.minValue
      this.maxValue = res.data.maxValue
      this.queryData = res.data.data
      this.rows = Math.round(this.queryData.length / 10 + 1)
      this.initMap()
    })
    axios.get('./api/china.json').then(async res => {
      this.chinaData = res.data
      this.initMap()
    })
    axios.get(this.$store.state.url + '/getMapRank').then(async res => {
      this.rank = res.data.rank
    })
  },
  mounted () {
    window.onresize = () => {
      location.reload()
    }
  },
  methods: {
    initMap () {
      const myChart = echarts.init(document.getElementById('main'))
      echarts.registerMap('CHN', this.chinaData)
      const option = {
        title: {
          text: '中国境内信息区域分布图 (2021-2021)',
          subtext: 'Data from www.ticklab.cn',
          sublink: 'www.ticklab.cn',
          left: 'right'
        },
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2
        },
        visualMap: {
          left: 'right',
          min: this.minValue,
          max: this.maxValue,
          inRange: {
            color: ['#313695', '#4575b4', '#74add1', '#abd9e9', '#e0f3f8', '#ffffbf', '#fee090', '#fdae61', '#f46d43', '#d73027', '#a50026']
          },
          text: ['High', 'Low'], // 文本，默认为数值文本
          calculable: true
        },
        toolbox: {
          show: true,
          left: 'left',
          top: 'top',
          feature: {
            dataView: { readOnly: true },
            restore: {},
            saveAsImage: {}
          }
        },
        series: [
          {
            name: 'CHN PopEstimates',
            type: 'map',
            map: 'CHN',
            emphasis: {
              label: {
                show: true
              }
            },
            // 文本位置修正
            textFixed: {
              Alaska: [20, -20]
            },
            data: this.queryData
          }
        ]
      }

      myChart.setOption(option)
    }
  }
}
</script>

<style scoped>

</style>
