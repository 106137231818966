<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="2000"
      controls
      indicators
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide img-src="../assets/homeCaro/home_banner_pic1.jpg"/>
      <b-carousel-slide img-src="../assets/homeCaro/home_banner_pic2.jpg"/>
      <b-carousel-slide img-src="../assets/homeCaro/home_banner_pic3.jpg"/>
    </b-carousel>
    <h2 class="text-center mt-4">最新动态</h2>
    <h6 class="text-center text-secondary">News</h6>
    <div>
      <div class="mt-4 container">
        <b-card class="border-0" :img-src="news.img_src" style="height: 200px" img-alt="News image" img-right>
          <b-card-text style="height: 100px">
            {{ news.brief }}
          </b-card-text>
          <b-button to="/news" variant="outline-primary">查看详情</b-button>
        </b-card>
      </div>
    </div>
    <div class="bg-dark text-light py-4 mt-4">
      <h2 class="text-center">新闻中心</h2>
      <h6 class="text-center text-secondary">News Center</h6>
      <div class="mt-4 container bg-dark">
        <b-row>
          <b-col cols="12" md="4" v-for="(item,key) in article" :key="key">
            <b-card class="mb-2 text-dark" style="height: 300px">
              <b-img :src="item.img_src" fluid style="height: 200px;width: 100%"></b-img>
              <b-card-text style="height: 100px">
                {{ item.brief }}
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-row style="display: flex;justify-content: flex-end">
          <b-button to="/news" variant="outline-light" class="px-5 mr-3 my-3">查看详情..</b-button>
        </b-row>
      </div>
    </div>
    <h2 class="text-center mt-4">关于我们</h2>
    <h6 class="text-center text-secondary">About</h6>
    <b-container>
      <b-card no-body class="overflow-hidden border-0">
        <b-row no-gutters>
          <b-col md="3">
            <b-card-img :src="aboutMeImage" alt="关于我们" class="rounded-0"/>
          </b-col>
          <b-col md="9">
            <b-card-body title="简述">
              <b-card-text>
                根据团队希望向浏览者传递的信息，进行网页的策划，便于传递信息以及和浏览者之间相互交流。
                做到让浏览者可以了解到有关蜱虫防治的相关信息，便于浏览者做好对蜱虫的预防工作。以及对被叮咬的患者的信息收集，有助于了解蜱虫的高发地以及危害，做到对风险地图的一个展示。
                根据团队希望向浏览者传递的信息，进行网页的策划，便于传递信息以及和浏览者之间相互交流。
                做到让浏览者可以了解到有关蜱虫防治的相关信息，便于浏览者做好对蜱虫的预防工作。以及对被叮咬的患者的信息收集，有助于了解蜱虫的高发地以及危害，做到对风险地图的一个展示。
              </b-card-text>
              <b-button to="/About" variant="primary">查看详情</b-button>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Home',
  data () {
    return {
      slide: 0,
      sliding: null,
      news: '',
      article: '',
      aboutMeImage: require('@/assets/aboutMe.png')
    }
  },
  created () {
    axios.get(this.$store.state.url + '/getTop1News').then(res => {
      this.news = res.data
    })
    axios.get(this.$store.state.url + '/getTop3News').then(res => {
      this.article = res.data.news
    })
  },
  methods: {
    onSlideStart () {
      this.sliding = true
    },
    onSlideEnd () {
      this.sliding = false
    }
  }
}
</script>
