<template>
  <div id="app">
    <b-navbar toggleable="md" type="dark" variant="dark">
      <b-navbar-brand href="#">
        <img src='favicon.ico' class="d-inline-block align-top" alt="TICKLAB">
        蜱虫实验室
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item v-for="(item,key) in menu" :key="key" :to="item.url" active>{{ item.name }}</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto d-none d-md-block">
          <b-nav-form>
            <b-form-input size="sm" class="mr-sm-2" placeholder="搜索想要的文章" v-model="search" @focus="focus"
                          @blur="unfocus"/>
            <b-button size="sm" class="my-2 my-sm-0" type="submit">搜索</b-button>
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view/>

    <div class="text-light mt-5 py-3" style="background-color: #363636">
      <b-container>
        <b-row>
          <b-col cols="12" md="5">
            <h4 class="mb-4">友情链接</h4>
          </b-col>
          <b-col cols="12" md="5">
            <h4 class="mb-3">联系我们</h4>
            <p class="footerTextStyle">联系人：李老师</p>
            <p class="footerTextStyle">地址：华中科技大学环境学院</p>
            <p class="footerTextStyle">
              备案号：
              <a class="footerTextStyle" href="https://beian.miit.gov.cn/">鄂ICP备17003739号-4</a>
            </p>
            <p class="footerTextStyle">Copyright 2021 蜱虫研究室版权所有</p>
          </b-col>
          <b-col cols="12" md="2">
            <h4 class="mb-2">官方微信</h4>
            <img src="./assets/scan.jpg" alt="二维码" class="w-100">
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-list-group :class="searchInputClass" style="right: 20px;top: 50px">
      <b-list-group-item v-for="(item,key) in searchList" :key="key">
        <b-link :href="item.link" class="text-dark">{{ item.title.slice(0, 11) }}</b-link>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import axios from 'axios'
// axios.defaults.withCredentials = true

export default {
  data () {
    return {
      menu: '',
      search: '',
      searchList: [],
      searchInputClass: 'position-absolute d-none',
      sessionID: ''
    }
  },
  name: 'App',
  created () {
    axios.get('./api/menu.json').then(res => {
      this.menu = res.data.menu
    })
    axios.get(this.$store.state.url + '/getSID').then(res => {
      this.sessionID = res.data.sid
    })
    setInterval(() => {
      axios.post(this.$store.state.url + '/saveLife', {
        sessionID: this.sessionID,
        event: 'beat'
      })
    }, 5000)
    window.addEventListener('beforeunload', this.beforeunloadHandler)
  },
  watch: {
    search (val) {
      axios.get(this.$store.state.url + '/getSearchNews?search=' + val).then(res => {
        this.searchInputClass = 'position-absolute'
        this.searchList = res.data.search
      })
    }
  },
  methods: {
    unfocus () {
      this.searchInputClass = 'position-absolute d-none'
    },
    focus () {
      this.searchInputClass = 'position-absolute'
    },
    beforeunloadHandler () {
      return axios.post(this.$store.state.url + '/saveLife', {
        sessionID: this.sessionID,
        event: 'closePage'
      })
    }
  }
}
</script>

<style scoped>
.footerTextStyle {
  font-size: 14px;
  color: #808080
}
</style>
