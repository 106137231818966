import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    url_dev_chen: 'http://192.168.100.174:8848/front',
    url_dev: 'http://127.0.0.1:8848/front',
    url_test: 'http://http://www.ticklab.cn/front',
    url_pro: '/front',
    url: '/front'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
