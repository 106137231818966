<template>
  <div>
    <b-img src="../assets/aboutMe2.png" class="w-100"/>
    <b-container class="my-4">
      <h2 class="text-center my-4">关于我们</h2>
      <h6 class="text-center text-secondary">About</h6>
      <b-container>
        <b-card no-body class="overflow-hidden border-0">
          <b-row no-gutters>
            <b-col md="3">
              <b-img src="../assets/aboutMe.png" alt="关于我们" class="rounded-0" style="width: 100%"/>
            </b-col>
            <b-col md="9">
              <b-card-body title="简述">
                <b-card-text>
                  根据团队希望向浏览者传递的信息，进行网页的策划，便于传递信息以及和浏览者之间相互交流。
                  做到让浏览者可以了解到有关蜱虫防治的相关信息，便于浏览者做好对蜱虫的预防工作。以及对被叮咬的患者的信息收集，有助于了解蜱虫的高发地以及危害，做到对风险地图的一个展示。
                  根据团队希望向浏览者传递的信息，进行网页的策划，便于传递信息以及和浏览者之间相互交流。
                  做到让浏览者可以了解到有关蜱虫防治的相关信息，便于浏览者做好对蜱虫的预防工作。以及对被叮咬的患者的信息收集，有助于了解蜱虫的高发地以及危害，做到对风险地图的一个展示。
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-container>
      <b-jumbotron header="蜱虫实验室" lead="TICK LAB By China Central Technology University" class="my-4">
        <p>
          PA蜱虫研究实验室是位于湖北省武汉市华中科技大学的大学附属实验室，致力于为全国范围内莱姆病和其他蜱虫传播疾病高危地区的人们提供快速、高评价、实验室质量的蜱虫检测。
          我们提供以qpcr为基础的蜱类检测，其准确性达到99.9%，可以检测18种导致疾病的病原体，如莱姆病、无形体病、巴贝斯虫、巴尔通体、埃利克体病、土拉热、宫本氏杆菌病、支原体病、南部蜱相关的皮疹病和落基山斑点热。
          不像传统实验室复杂的测试申请表格，PA
          Tick研究实验室有一个简单，易于使用的界面订购Tick测试。您只需订购您的测试，将您的tick邮寄到我们的实验室，您的结果将在收到实验室收据后的24-72小时内准备好——保证。有蜱虫吗?现在开始!
        </p>
        <b-button variant="primary" to="/" class="px-5">回到首页</b-button>
      </b-jumbotron>
    </b-container>
  </div>
</template>
<script>
export default {
  name: 'About'
}
</script>
